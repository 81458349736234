import React, { Component } from 'react'

class Image extends Component {
  getImage(stack, format = 'jpg') {
    const url =
      'https://' +
      this.props.fields.org +
      '.rokka.io/' +
      stack +
      '/' +
      this.props.fields.hash +
      '.' +
      format

    return (
      <div className={'glitchImage'}>
        <a href={url} target="liipPicsFew">
          <img
            src={url}
            id="images"
            width="100%"
            style={{ maxWidth: this.props.fields.width + 'px' }}
            alt=""
          />
        </a>
      </div>
    )
  }

  render() {
    if (!this.props.fields.hash) {
      return null
    }

    let gridClass = 'grid__item sm-w-1/2 lg-w-1/2'
    if (this.props.formHidden) {
      gridClass = 'grid__item sm-w-2/3 lg-w-2/3'
    }
    const gifAmount = this.props.fields.gifAmount.split(',')
    return (
      <div className="grid grid--large grid--multiline grid--top">
        <div className={gridClass}>
          {this.getImage('www_inarticle')}
          {this.getImage(
            'glitch' +
              (this.props.defaultFields.random !== this.props.fields.random
                ? '/glitch-random-' + this.props.fields.random
                : '') +
              (this.props.defaultFields.glitchAmount !==
              this.props.fields.glitchAmount
                ? '/glitch-amount-' + this.props.fields.glitchAmount
                : '') +
              (this.props.defaultFields.brightness !==
              this.props.fields.brightness
                ? '/modulate-brightness-' + this.props.fields.brightness
                : '') +
              (this.props.defaultFields.width !== this.props.fields.width
                ? '/resize-width-' + this.props.fields.width
                : '')
          )}
          {this.getImage(
            'piccolour' +
              (this.props.defaultFields.width !== this.props.fields.width
                ? '/resize-width-' + this.props.fields.width
                : '') +
              (this.props.defaultFields.opacity !== this.props.fields.opacity
                ? '/alpha-opacity-' + this.props.fields.opacity
                : '') +
              (this.props.defaultFields.colour !== this.props.fields.colour
                ? '/v-c-' + this.props.fields.colour
                : '')
          )}
          {this.getImage(
            'glitchc' +
              (this.props.defaultFields.random !== this.props.fields.random
                ? '/glitch-random-' + this.props.fields.random
                : '') +
              (this.props.defaultFields.glitchAmount !==
              this.props.fields.glitchAmount
                ? '/glitch-amount-' + this.props.fields.glitchAmount
                : '') +
              (this.props.defaultFields.width !== this.props.fields.width
                ? '/resize-width-' + this.props.fields.width
                : '') +
              (this.props.defaultFields.opacity !== this.props.fields.opacity
                ? '/alpha-opacity-' + this.props.fields.opacity
                : '') +
              (this.props.defaultFields.brightness !==
              this.props.fields.brightness
                ? '/modulate-brightness-' + this.props.fields.brightness
                : '') +
              (this.props.defaultFields.colour !== this.props.fields.colour
                ? '/v-c-' + this.props.fields.colour
                : '')
          )}
          {this.props.fields.removeGif
            ? null
            : this.getImage(
                'dynamic' +
                  '/resize-width-' +
                  Math.floor(this.props.fields.width / 2) +
                  '--addframes-delay-' +
                  this.props.fields.gifDelay +
                  '-frames-' +
                  'in,'.repeat(this.props.fields.gifStart) +
                  'dynamic__resize___width___' +
                  Math.floor(this.props.fields.width / 2) +
                  '____glitch___amount___' +
                  gifAmount[0] +
                  ':' +
                  this.props.fields.hash +
                  ',dynamic__resize___width___' +
                  Math.floor(this.props.fields.width / 2) +
                  '____glitch___amount___' +
                  gifAmount[1] +
                  ':' +
                  this.props.fields.hash +
                  ',dynamic__resize___width___' +
                  Math.floor(this.props.fields.width / 2) +
                  '____glitch___amount___' +
                  gifAmount[2] +
                  '___random___' +
                  this.props.fields.random +
                  ':' +
                  this.props.fields.hash +
                  '/o-af-1',
                'gif'
              )}
        </div>
      </div>
    )
  }
}

export default Image
