import React, { Component } from 'react'
import rokka from 'rokka'
class Image extends Component {
  rka = new rokka({ renderHost: 'https://drupal20.rokka.io/' })

  getImage(url) {
    return (
      <div className={'glitchImage'}>
        <a href={url} target="liipPicsFew">
          <img
            src={url}
            id="images"
            width="100%"
            style={{ maxWidth: this.props.fields.width + 'px' }}
            alt=""
          />
        </a>
      </div>
    )
  }

  render() {
    let gridClass = 'grid__item sm-w-1/2 lg-w-3/4'
    if (this.props.formHidden) {
      gridClass = 'grid__item sm-w-2/3 lg-w-2/3'
    }
    let leftFeature = 'eyeLeft'
    let rightFeature = 'eyeRight'

    if (this.props.fields.secondary === 'e3547f') {
      leftFeature = 'mouthLeft'
      rightFeature = 'mouthRight'
    }
    const url = this.rka.render.addStackVariables(
      `https://drupal20.rokka.io/dynamic/resize-width-${this.props.fields.width}--face-secondary_image-${this.props.fields.secondary}-anchor-${this.props.fields.anchor}-leftFeature-${leftFeature}-rightFeature-${rightFeature}/o-af-1/${this.props.fields.hash}/drupalized.jpg`,
      {}
      // Object.assign({}, this.props.fields)
    )
    return (
      <div className="grid grid--large grid--multiline grid--top">
        <div className={gridClass}>
          {this.getImage(url)}
          Link to image: <a href={url}>{url}</a>
        </div>
      </div>
    )
  }
}
export default Image
