import React, { Component } from 'react'
import _ from 'lodash'

class UploadForms extends Component {
  render() {
    let form = (
      <form
        className="contact-form"
        name="trophy-form"
        onSubmit={this.props.onSubmit}
      >
        {this.getChoiceField('bg', 'Background', {
          black: 'Black',
          //green: 'Green',
          swissmap: 'White Swiss Map',
        })}
        {this.getFormField('text1', 'Text 1')}
        {this.getFormField('text2', 'Text 2')}
        {this.getFormField('width', 'Width')}
      </form>
    )

    return <div className="form-group">{form}</div>
  }

  getFormField = (name, title) => {
    return (
      <div className={'formField'}>
        {title}:{' '}
        <input
          className="field  field--light field--primary"
          value={this.props.fields[name]}
          name={name}
          onChange={this.props.onInputChange}
        />
      </div>
    )
  }
  getChoiceField = (name, title, choices) => {
    return (
      <div className={'formField'}>
        {title}:{' '}
        <select
          className="field  field--light field--primary"
          value={this.props.fields[name]}
          name={name}
          onChange={this.props.onInputChange}
        >
          {this.getOptions(choices)}
        </select>
      </div>
    )
  }

  getOptions = (choices) => {
    const options = []

    _.forEach(choices, (item, index) => {
      options.push(
        <option key={index} value={index}>
          {item}
        </option>
      )
    })
    return options
  }
}

export default UploadForms
