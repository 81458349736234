import React, { Component } from 'react'
import rokka from 'rokka'
class Image extends Component {
  rka = new rokka({ renderHost: 'https://liip.rokka.io/' })

  getImage(url) {
    return (
      <div className={'glitchImage'}>
        <a href={url} target="liipPicsFew">
          <img
            src={url}
            id="images"
            width="100%"
            style={{ maxWidth: this.props.fields.width + 'px' }}
            alt=""
          />
        </a>
      </div>
    )
  }

  render() {
    let gridClass = 'grid__item sm-w-1/2 lg-w-3/4'
    if (this.props.formHidden) {
      gridClass = 'grid__item sm-w-2/3 lg-w-2/3'
    }
    //d7726d
    //506ebf
    let hash = '559dae'
    let color = 'FAFAFA'
    if (this.props.fields.bg === 'swissmap') {
      hash = '9941c4'
      color = '222222'
    }
    const url = this.rka.render.addStackVariables(
      'https://liip.rokka.io/meet/' + hash + '/liip-meet-background.jpg',
      Object.assign({}, this.props.fields, { bg: undefined, c: color })
    )
    return (
      <div className="grid grid--large grid--multiline grid--top">
        <div className={gridClass}>
          {this.getImage(url)}
          Link to image: <a href={url}>{url}</a>
        </div>
      </div>
    )
  }
}
export default Image
