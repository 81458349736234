import React, { Component } from 'react'
import _ from 'lodash'

class UploadForms extends Component {
  render() {
    let form = (
      <form
        className="contact-form"
        name="trophy-form"
        onSubmit={this.props.onSubmit}
      >
        Image:
        <input
          accept="image/*"
          className="field  field--light field--primary"
          placeholder="Image"
          type="file"
          name="uploadImage"
          onChange={this.props.onInputChange}
        />
        <br />
        {this.getFormField('hash', 'Rokka Hash')}
        {this.getChoiceField('secondary', 'Gimmick', {
          '291fff': 'Glasses',
          e3547f: 'Mask',
        })}
        {this.getFormField('anchor', 'Anchor')}
        {this.getFormField('width', 'Width')}
        <input
          type="submit"
          className="btn btn--primary"
          value={this.props.buttonText}
        />
        {this.props.submitted === true && (
          <p>
            <b>Image Uploaded. </b>
          </p>
        )}
      </form>
    )

    return <div className="form-group">{form}</div>
  }

  getFormField = (name, title) => {
    return (
      <div className={'formField'}>
        {title}:{' '}
        <input
          className="field  field--light field--primary"
          value={this.props.fields[name]}
          name={name}
          onChange={this.props.onInputChange}
        />
      </div>
    )
  }
  getChoiceField = (name, title, choices) => {
    return (
      <div className={'formField'}>
        {title}:{' '}
        <select
          className="field  field--light field--primary"
          value={this.props.fields[name]}
          name={name}
          onChange={this.props.onInputChange}
        >
          {this.getOptions(choices)}
        </select>
      </div>
    )
  }

  getOptions = (choices) => {
    const options = []

    _.forEach(choices, (item, index) => {
      options.push(
        <option key={index} value={index}>
          {item}
        </option>
      )
    })
    return options
  }
}

export default UploadForms
