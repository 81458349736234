import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import AppMeet from './Meet/App'
import AppDrupal from './Drupalize/App'

if (window.location.pathname.startsWith('/meet')) {
  ReactDOM.render(<AppMeet />, document.getElementById('root'))
  document.title = 'Liip Meet Background'
} else if (window.location.pathname.startsWith('/drupal')) {
  ReactDOM.render(<AppDrupal />, document.getElementById('root'))
  document.title = 'Drupalize me'
} else {
  ReactDOM.render(<App />, document.getElementById('root'))
}
